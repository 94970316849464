<template>
<div>
    <app-header></app-header>

    <div class="login">
        <div class="login-logo text-center">
            <img src="../../assets/images/logo.png" alt="" class="" />
            <p class="text-center">{{ $t("Register a request to join suppliers") }}</p>
        </div>
        <div class="text-center px-2">
            <div method="">
                <div class="page">
                    <div class="field">
                        <label for="name">{{$t("WorkshopFactoryName")}} * </label>
                        <InputText id="name" type="text" v-model="body.factoryName" :placeholder="$t('WorkshopFactoryName')" />
                    </div>
                    <div class="field">
                        <label for="name">{{$t("responsableName")}} *</label>
                        <InputText id="name" type="text" v-model="body.name" :placeholder="$t('responsableName')" />
                    </div>
                    <div class="field">
                        <label for="phone">{{ $t("phone") }}</label>
                        <InputText id="phone" type="text" v-model="body.phone" :placeholder="$t('phone')" />
                    </div>
                    <div class="field">
                        <label for="email">{{ $t("email") }}</label>
                        <InputText id="email" type="text" v-model="body.email" :placeholder="$t('email')" />
                    </div>
                    <div class="field">
                        <label for="address">{{$t("WorkshopFactoryAddress")}}</label>
                        <InputText id="address" type="text" v-model="body.address" :placeholder="$t('WorkshopFactoryAddress')" />
                    </div>
                    <div class="field">
                        <label for="website">{{$t("WebsiteorFacebookPageorInstagramoftheWorkshopFactory")}}</label>
                        <InputText id="website" type="text" v-model="body.website" :placeholder="$t('WebsiteorFacebookPageorInstagramoftheWorkshopFactory')" />
                    </div>
                    <div class="field">
                        <label for="website">{{$t("Do you have a factory or a workshop")}} *</label>
                        <div class="field-item">
                            <Checkbox id="haveFactory" v-model="body.haveFactory" trueValue="true" falseValue="false" :binary="true" disabled/>
                            <span  for="haveFactory">{{ $t('haveFactory') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveWorkshop" v-model="body.haveWorkshop" trueValue="true" falseValue="false" :binary="true" disabled />
                            <span style="margin-left: 10px" for="haveWorkshop">{{ $t('haveWorkshop') }}</span>
                        </div>

                    </div>

                    <div class="field">
                        <label for="website">{{$t("What legal documents do you have available")}}</label>
                        <div class="field-item">
                            <Checkbox id="haveCommercialRegistration" v-model="body.haveCommercialRegistration" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveCommercialRegistration">{{ $t('haveCommercialRegistration') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveTaxCard" v-model="body.haveTaxCard" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveTaxCard">{{ $t('haveTaxCard') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveIndustrialRegistration" v-model="body.haveIndustrialRegistration" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveIndustrialRegistration">{{ $t('haveIndustrialRegistration') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="noLegalDocuments" v-model="body.noLegalDocuments" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="noLegalDocuments">{{ $t('noLegalDocuments') }}</span>
                        </div>

                    </div>

                    <div class="field">
                        <label for="website">{{$t("What are the main products manufactured by you")}} *</label>
                        <div class="field-item">
                            <Checkbox id="haveMainSofasandCornerSofas" v-model="body.haveMainSofasandCornerSofas" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveMainSofasandCornerSofas">{{ $t('SofasandCornerSofas') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveMainChairs" v-model="body.haveMainChairs" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveMainChairs">{{ $t('chairs') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveMainTables" v-model="body.haveMainTables" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveMainTables">{{ $t('tables') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveMainBedrooms" v-model="body.haveMainBedrooms" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveMainBedrooms">{{ $t('bedRooms') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveMainDiningRoom" v-model="body.haveMainDiningRoom" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveMainDiningRoom">{{ $t('dinningTable') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveMainOfficeFurniture" v-model="body.haveMainOfficeFurniture" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveMainOfficeFurniture">{{ $t('officeFurniture') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveMainDecorationsandAntiques" v-model="body.haveMainDecorationsandAntiques" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveMainDecorationsandAntiques">{{ $t('Decorations and antiques') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveMainOutdoorFurniture" v-model="body.haveMainOutdoorFurniture" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveMainOutdoorFurniture">{{ $t('Outdoor furniture') }}</span>
                        </div>


                    </div>

                    <div class="field">
                        <label for="website">{{$t("What types of products can be assigned to you when needed")}} *</label>
                        <div class="field-item">
                            <Checkbox id="haveAssignedSofasandCornerSofas" v-model="body.haveAssignedSofasandCornerSofas" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveAssignedSofasandCornerSofas">{{ $t('SofasandCornerSofas') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveAssignedChairs" v-model="body.haveAssignedChairs" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveAssignedChairs">{{ $t('chairs') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveAssignedTables" v-model="body.haveAssignedTables" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveAssignedTables">{{ $t('tables') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveAssignedBedrooms" v-model="body.haveAssignedBedrooms" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveAssignedBedrooms">{{ $t('bedRooms') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveAssignedDiningRoom" v-model="body.haveAssignedDiningRoom" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveAssignedDiningRoom">{{ $t('dinningTable') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveAssignedOfficeFurniture" v-model="body.haveAssignedOfficeFurniture" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveAssignedOfficeFurniture">{{ $t('officeFurniture') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveAssignedDecorationsandAntiques" v-model="body.haveAssignedDecorationsandAntiques" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveAssignedDecorationsandAntiques">{{ $t('Decorations and antiques') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveAssignedOutdoorFurniture" v-model="body.haveAssignedOutdoorFurniture" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveAssignedOutdoorFurniture">{{ $t('Outdoor furniture') }}</span>
                        </div>

                    </div>

                    <div class="field">
                        <label for="website">{{$t("What materials do you rely on in your manufacturing process")}} *</label>
                        <div class="field-item">
                            <Checkbox id="haveNaturalWood" v-model="body.haveNaturalWood" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveNaturalWood">{{ $t('haveNaturalWood') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveMDFWood" v-model="body.haveMDFWood" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveMDFWood">{{ $t('haveMDFWood') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveSteel" v-model="body.haveSteel" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveSteel">{{ $t('haveSteel') }}</span>
                        </div>
                        <div class="field-item">
                            <Checkbox id="haveStainlessSteel" v-model="body.haveStainlessSteel" trueValue="true" falseValue="false" :binary="true" />
                            <span style="margin-left: 10px" for="haveStainlessSteel">{{ $t('haveStainlessSteel') }}</span>
                        </div>
                    </div>

                    <div class="field">
                        <label for="website">{{$t("Do you have ready stock of products")}} *</label>
                        <div class="field-item">
                            <RadioButton v-model="body.haveProductStock" name="haveProductStock" value="true" />
                            <span style="" for="haveStainlessSteel">{{ $t('yes') }}</span>
                        </div>
                        <div class="field-item">
                            <RadioButton v-model="body.haveProductStock" name="haveProductStock" value="false" />
                            <span for="haveStainlessSteel">{{ $t('no') }}</span>
                        </div>
                    </div>
                    <div class="field">
                        <label for="website">{{$t("What is the manufacturing time for the products listed in your catalog")}} *</label>
                        <div class="field-item">
                            <RadioButton v-model="body.whatIsManufacturingTime" name="whatIsManufacturingTime" value="3" />
                            <span style="" for="haveStainlessSteel">{{$t("lessThan3days")}}</span>
                        </div>
                        <div class="field-item">
                            <RadioButton v-model="body.whatIsManufacturingTime" name="whatIsManufacturingTime" value="3-7" />
                            <span for="haveStainlessSteel">{{$t("from3To7Days")}}</span>
                        </div>
                        <div class="field-item">
                            <RadioButton v-model="body.whatIsManufacturingTime" name="whatIsManufacturingTime" value="7-15" />
                            <span for="haveStainlessSteel">{{$t("from7To15Days")}}</span>
                        </div>
                        <div class="field-item">
                            <RadioButton v-model="body.whatIsManufacturingTime" name="whatIsManufacturingTime" value="15" />
                            <span for="haveStainlessSteel">{{$t("greaterThan15Days")}}</span>
                        </div>
                    </div>

                    <div class="field">
                        <label for="website">{{$t("Are you partnered with other online platforms to sell furniture")}} *</label>
                        <div class="field-item">
                            <RadioButton v-model="body.subscribedToOtherOnlinePlatforms" name="subscribedToOtherOnlinePlatforms" value="true" />
                            <span style="" for="haveStainlessSteel">{{ $t('yes') }}</span>
                        </div>
                        <div class="field-item">
                            <RadioButton v-model="body.subscribedToOtherOnlinePlatforms" name="subscribedToOtherOnlinePlatforms" value="false" />
                            <span for="haveStainlessSteel">{{ $t('no') }}</span>
                        </div>
                    </div>
                    <div class="field">
                        <label for="website">{{$t("Is it possible for you to deliver the product to our warehouse")}} *</label>
                        <div class="field-item">
                            <RadioButton v-model="body.deliverTheProduct" name="deliverTheProduct" value="true" />
                            <span style="" for="haveStainlessSteel">{{ $t('yes') }}</span>
                        </div>
                        <div class="field-item">
                            <RadioButton v-model="body.deliverTheProduct" name="deliverTheProduct" value="false" />
                            <span for="haveStainlessSteel">{{ $t('no') }}</span>
                        </div>
                    </div>

                    <div style="margin-top:10%"></div>
                    <button class="btn btn-primary btn-login" @click="add()">
                        {{ $t("sendRequest") }}
                    </button>
                    <a style="display:none" href="/" id="sendhome">send home</a>
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import AppHeader from "../../layouts/header.vue";
export default {
    components: {
        AppHeader,
    },
    data() {
        return {
            body: {
                email: null,
                address: null,
                factoryName: null,
                phone: null,
                name: null,
            },

        };
    },

    methods: {
        add() {
console.log("this.body" , this.body);

            this.$http.post("vendorsRequests", this.body).then(
                (res) => {

                    this.$toast.add({
                        severity: "success",
                        summary: this.$t("Done Successfully"),
                        detail: this.$t("Your request to join has been sent successfully"),
                        life: 3000,
                    });
                    // document.getElementById("sendhome").click()
                },
                (err) => {

                    this.$toast.add({
                        severity: "error",
                        summary: this.$t("Error"),
                        detail: err.response.data.message,
                        life: 3000,
                    });
                }
            );

        },
    },
    async created() {},
};
</script>

<style scoped>
.field {
    margin-bottom: 1.5rem;
}

.field label {
    display: block;
    margin-bottom: 1rem;
    font-weight: bold;
}

.field-item {
    display: flex;
    align-items: center;
    margin-top: 10px
}

.field-item span {
    margin-right: 10px ;font-size: 18px;font-weight: bold
}

</style>
